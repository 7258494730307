import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import './footer.scss'

const Footer = () => {
  return (
    <Container maxWidth="lg" className="footer">
      <Grid container>
        <Grid item xs={4}>
          <Box>
            <Typography>(3822) 215-911</Typography>
            <Typography>(3822) 216-911</Typography>
            <Link href="mailto:penetron.tomsk@mail.ru">
              penetron.tomsk@mail.ru
            </Link>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography style={{ fontWeight: '600' }}>Адрес:</Typography>
          <Typography>
            634009, ул. Войкова, 8/6, Томск​
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="right">
            © {new Date().getFullYear()} «Служба Спасения Бетона»
          </Typography>
          <Typography align="right">ГК «Пенетрон-Россия»</Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Footer
