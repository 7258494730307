import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PromoBlock from './promo-block/promo-block'
import Menu from './menu/menu'
import Footer from './footer/footer'

import './layout.css'
import './my-layout.scss'
import { Breadcrumbs, Typography } from '@material-ui/core'

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Paper position="fixed" className="my-header">
        <Container maxWidth="lg">
          <Toolbar>
            <Grid container spacing={3}>
              <Grid item md={6} xs={10}>
                <Button className="my-menu-button">
                  <Link href="/" className="header-link" underline="none">
                    {data.site.siteMetadata.title}
                  </Link>
                </Button>
              </Grid>
              <Grid item md={6} xs={2}>
                <Menu />
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </Paper>
      <PromoBlock
        isMainPage={props.isMainPage}
        title={props.title}
        caption={props.caption}
      />
      <Container maxWidth="lg" className="my-content-wrapper js-content">
        <Box p={3}>
          <Breadcrumbs aria-label="breadcrumb">
            {(props.breadCrumbs || []).map(({ key, link, name }, index) => {
              if (index < props.breadCrumbs.length - 1) {
                return (
                  <Link key={key} href={link}>
                    {name}
                  </Link>
                )
              }
              return (
                <Typography key={key} color="textPrimary">
                  {name}
                </Typography>
              )
            })}
          </Breadcrumbs>
          <main>{props.children}</main>
        </Box>
      </Container>
      <Footer />
    </>
  )
}

export default Layout
