export const MENU_ITEMS = [
  {
    name: 'Продукция',
    link: '/products',
    key: 'products',
  },
  {
    name: 'Где купить?',
    link: '/shops',
    key: 'shops',
  },
  {
    name: 'Контакты',
    link: '/contacts',
    key: 'contacts',
  },
]
