import React, { useEffect, useState, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { MENU_ITEMS } from '../../constants/menu'
import './menu.scss'
import {
  IconButton,
  Icon,
  MenuItem,
  Menu,
  Link,
  Hidden,
} from '@material-ui/core'

const MyMenu = () => {
  useEffect(() => {
    const content = document.querySelector('.js-content')
    const handleScroll = () => {
      if (window.scrollY > content.offsetTop - 120) {
        document.body.classList.add('scroll-content')
      } else {
        document.body.classList.remove('scroll-content')
      }
    }

    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleDrawerOpen = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(false)
  }, [])

  return (
    <Grid container spacing={3} alignItems="center" justify="flex-end">
      <Hidden mdUp>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
        >
          <Icon>menu</Icon>
        </IconButton>
      </Hidden>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        {MENU_ITEMS.map(({ link, name, key }) => (
          <Link key={key} color="inherit" underline="none" href={link}>
            <MenuItem>{name}</MenuItem>
          </Link>
        ))}
      </Menu>
      <Hidden smDown>
        {MENU_ITEMS.map(({ link, name, key }) => (
          <Grid key={key} item>
            <Button href={link} className="header-link">
              {name}
            </Button>
          </Grid>
        ))}
      </Hidden>
    </Grid>
  )
}

export default MyMenu
