import React from 'react'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import './promo-block.scss'

const PromoBlock = ({ isMainPage, title, caption }) => {
  const mod = isMainPage ? '--main' : ''
  const mainTitle = title || 'Служба Спасения Бетона'

  return (
    <Paper className={`promo-block ${mod}`}>
      <div className="promo-block__wrapper">
        <div className="promo-block__image" />
        <Container className="promo-block__container">
          <Grid container alignItems="center" justify="center">
            <Grid item xs={12}>
              <Typography
                variant="h3"
                component="h1"
                className="promo-block__title"
              >
                {mainTitle}
              </Typography>
              <Box m={3} className="promo-block__caption">
                <Typography
                  variant="h6"
                  component="h2"
                  className="promo-block__caption-text"
                >
                  Группа компаний «Пенетрон-Россия» создана в 1991 году. В
                  настоящее время холдинг производит и поставляет материалы
                  нового поколения для строительства, ремонта, восстановления и
                  гидроизоляции строительных конструкций.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Paper>
  )
}

export default PromoBlock
